import React, { useState, useEffect } from "react";
//component
import InsideNav from "../../components/InsideNav";
import Loading from "../../components/Loading";
import InsurerData from "../../json/insurer/Insurer.json";
import Countfunc from "../../assets/javascript/countfunc";
import axios from "axios";
import LineLogin from '../../assets/javascript/lineLogin'
const CIBAgent = () => {
    useEffect(() => {
        LineLogin('/CIB_agent')
        Countfunc('經代專區', 10)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/CIB_agent');
                        window.location.href = '/login';
                    } else {
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
                console.log(err)
            })
    }, []);
    let data = InsurerData.data[0].CIBAgent;
    const insideNavItem = [
        {
            text: '壽險',
        },
        {
            text: '產險',
        }
    ]

    let [option, setOption] = useState(data[0].option);
    let [companyList, setCompanyList] = useState(data[0].companyType);
    let [isLoading, setIsLoading] = useState(false)

    //壽險、產險列表切換 click 事件
    const handleClick = (e) => {
        e.preventDefault();
        let { nodeName, innerText } = e.target;
        if (nodeName !== "A") {
            return
        }
        data.forEach((item) => {
            if (innerText === item.option) {
                setCompanyList(item.companyType);
                setOption(item.option);
            }
        })
    }

    //取得 API SSO URL
    const handleGetCompanyData = (e, type, code, url) => {
        setIsLoading(true)
        e.preventDefault();
        const userData = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
        let userId = JSON.parse(unescape(userData)).Id


        if (code === 'noSSO') {
            window.location.href = `${url}?openExternalBrowser=1`
        } else {
            let API = `${process.env.REACT_APP_GOLDEN_CIBagent_API}/sales/rest/sup/getSsoUrl3/${userId}/${code}/經代專區/${type}`
            axios.post(API)
                .then((res) => {
                    let { sso, ssoUrl, pptRno, regRno, prdName } = res.data
                    if (sso) {
                        if (pptRno || regRno) { //產險,壽險
                            window.location.href = ssoUrl
                            return
                        }
                        if (prdName) {
                            alert('沒有設定經代專區相關資料，不能使用快速登錄。')
                            setIsLoading(false)
                        } else {
                            alert('沒有壽/產險登錄證字號相關資料，不能使用快速登錄。')
                            setIsLoading(false)
                        }
                    } else {
                        setIsLoading(false)
                        //直接開啟連結
                        window.location.href = `${url}?openExternalBrowser=1`
                    }
                })
                .catch((err) => {
                    console.log(err)
                    alert('網路連結錯誤！請聯繫管理人員')
                    setIsLoading(false)
                })
        }
    }

    //取得保險公司列表
    const getCompany = companyList.sort((a, b) => { return a.num - b.num }).map((item) => {
        if (option === "產險") {
            return <li key={item.name} className="col-4 col-md-3 col-lg-2 py-3 text-center" >
                <button className="btn" onClick={e => handleGetCompanyData(e, '1', item.companyCode, item.url)}>
                    <img src={require(`../../assets/images/companyLOGO/property/${item.name}.png`).default} alt={`${item.name} logo`} className="img-fluid" style={{ width: "80%" }} />
                </button>
            </li>
        }
        return <li key={item.name} className="col-4 col-md-3 col-lg-2 py-3 text-center" >
            <button className="btn" onClick={e => handleGetCompanyData(e, '2', item.companyCode, item.url)}>
                <img src={require(`../../assets/images/companyLOGO/life/${item.name}.png`).default} alt={`${item.name} logo`} className="img-fluid" style={{ width: "80%" }} />
            </button>
        </li>
    });

    return (
        <>
            <Loading isLoading={isLoading} />
            <InsideNav option={option} insideNavItem={insideNavItem} handleClick={handleClick}></InsideNav>
            <div className="container">
                <ul className="list-unstyled row">
                    {getCompany}
                </ul>
            </div>
        </>
    );
}

export default CIBAgent;