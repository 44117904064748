import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Accordion } from 'react-bootstrap'
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import InsideNav from "../../components/InsideNav";
import { dateChange, toCurrency } from "../../assets/javascript/calTool";
import Loading from "../../components/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import LineLogin from "../../assets/javascript/lineLogin";
import downloadtoBase64url from "../../assets/javascript/downloadtoBase64url";

const InsurancePolicyRead = () => {
    const history = useHistory();
    let code = history.location.pathname.split('/')[2];
    let insNo = history.location.pathname.split('/')[3];

    let [isLoading, setIsLoading] = useState(true)

    // insideNav component 選單選項
    let insideNavItem = [
        { text: '基本資料' }, { text: '保障內容' }
    ]
    let [option, setOption] = useState(insideNavItem[0].text); //預設為第一個
    let [isMain, setIsMain] = useState('1'); //主約:1 ; 附約:0
    let [searchInput] = useState(sessionStorage.getItem('searchInput'))

    const handleClick = (e) => {
        let { innerText, nodeName } = e.target
        if (nodeName !== 'A') {
            return
        }
        setOption(innerText);
        setIsMain('1')
    }
    // insideNav component 選單選項 end

    let [data, setData] = useState([])
    let [insData, setInsData] = useState([])
    useEffect(() => {
        LineLogin('/insurance_policy'); //LINE 登入狀態驗證
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`
        // axios.default.withCredentials = true;
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/insurance_policy');
                        window.location.href = '/login'
                        return
                    } else if (res.data.ResponseCode === '0') {
                        setData(res.data.Data)
                        setIsLoading(false)
                    } else if (res.data.ResponseCode === '-20') {
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        alert('查詢失敗!')
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);


    useEffect(() => {
        data.forEach((item, index) => {
            if (code == item.Code) {
                item.InsData.forEach((kidItem, kidIndex) => {
                    kidItem.forEach((aItem, aIndex, aArray) => {
                        if (insNo === aItem.Ins_No) {
                            setInsData(old => [...old, aItem])
                        }
                    })
                })
            }
        })
    }, [data]);

    //
    let showData = [];
    let sdCodeAry = []; //要保書
    const showDataFun = () => {
        const set = new Set();
        showData = insData.filter(item => !set.has(item.Pro_Name) ? set.add(item.Pro_Name) : false); //過濾 Line ID 相同的人員
        let sum = 0;
        showData.forEach((sItem, sIndex, sArray) => {
            if (!sItem?.Void) {
                sum += sItem?.FYP
            }

            if (sItem.SDCode == null || sItem.SDCode == '' || sItem.SDCode == undefined) {
                return
            } else {
                //SDCode 要保書拆解
                sdCodeAry = sItem.SDCode.split(',')
            }
        })

        showData.map(sItem => sItem.FYCsum = sum)
    }
    showDataFun();
    const handleMainChange = (e) => {
        setIsMain(e.target.value)
    }

    //重新查詢 start
    const handleSearchReset = (e, type) => {
        e.preventDefault();
        if (type == 'reset') {
            sessionStorage.removeItem('searchInput')
        }
        window.location.href = '/insurance_policy'; //返回指定頁面
    }
    const SearchReset = {
        bottom: '0',
        width: '100%',
        zIndex: '60'
    }
    //重新查詢 end

    const handleDownloadFile = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        downloadtoBase64url('InsList/file=', dataset.filename, setIsLoading)
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className="top-nav w-100 py-3">
                        <p className="text-light text-center fw-bolder">保單明細</p>
                    </div>
                    <InsideNav insideNavItem={insideNavItem} option={option} handleClick={handleClick}></InsideNav>
                </div>
                <div className="d-table" style={{ height: '130px' }}></div>
                <div className={`container ${option == '基本資料' ? 'd-block' : 'd-none'}`} style={{ background: 'rgba(255,255,255,50%)' }}>
                    {
                        showData.filter((val, index, ary) => {
                            if (insNo == val.Ins_No) {
                                return ary.indexOf(val) === index
                            }
                        }).map((item, index) => {
                            if (index === 0) {
                                return (
                                    <ul className="list-unstyled p-0 pb-4">
                                        {
                                            item.Main == 1 && item.MainVoid &&
                                            <li className="mx-2 my-3">
                                                <span className="badge bg-secondary" style={{ fontSize: '16px' }}>已註銷</span>
                                            </li>
                                        }
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">保單號碼</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.Ins_No}</span>
                                            </div>
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">保險公司</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.Supplier_Name}</span>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">繳別</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.PayType === 'Y' ? '年繳' : ''}</span>
                                            </div>
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">幣別</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.crc}</span>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">合計保費</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.FYCsum !== null && toCurrency(Math.round(item.FYCsum))}</span>
                                            </div>
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">生效日</span>
                                                <span className="d-block text-dark-blue fw-bolder">
                                                    {dateChange(item.Effe_Date)}
                                                </span>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">要保文件</span>
                                                <div>
                                                    {
                                                        sdCodeAry.map((item, index) => {
                                                            return (
                                                                <a href="#" data-download={item} data-filename={item} target="blank" className="d-block text-dark-blue fw-bolder mb-2" onClick={handleDownloadFile}>文件{index + 1} 【下載】</a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">主約商品</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.Pro_Name}</span>
                                            </div>
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">要保人</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.name}</span>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">主約被保人</span>
                                                <span className="d-block text-dark-blue fw-bolder">{item.Name}</span>
                                            </div>
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title me-2">保單聯絡地址</span>
                                                <span className="d-block text-dark-blue fw-bolder">
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                    <span className="ms-2">{item.Address}</span>
                                                </span>
                                            </div>
                                        </li>
                                        <li className="row px-2">
                                            <div className="col-md-6 d-flex border-bottom py-3">
                                                <span className="d-block query-title">保單連絡電話</span>
                                                <a href="tel:" className="d-block text-dark-blue fw-bolder text-end">
                                                    <FontAwesomeIcon icon={faCommentDots} />
                                                    <span className="ms-2">{item.Mobile}</span>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            }
                        })
                    }
                </div>
                <div className={`container ${option == '保障內容' ? 'd-block' : 'd-none'}`} style={{ background: 'rgba(255,255,255,50%)' }}>
                    <select id="maincovenant" className="form-select w-100" value={isMain} select={isMain} onChange={handleMainChange}>
                        <option value="1" select>主約</option>
                        <option value="0" select>附約</option>
                    </select>
                    {
                        showData.filter((val, valIndex, valAry) => {
                            if (insNo == val.Ins_No && (isMain == '1' || isMain == null) && (val.Main == '1' || val.Main == null)) {
                                return valAry.indexOf(val) == valIndex
                            } else if (insNo == val.Ins_No && isMain == '0' && val.Main == '0') {
                                return valAry.indexOf(val) == valIndex
                            }
                        }).map((item, index, ary) => {
                            if (item.Main == '1' || item.Main == null) {
                                if (index === 0) {
                                    return (
                                        <ul className="list-unstyles p-0">
                                            <li className="row px-2">
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">生效日</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{dateChange(item.Effe_Date)}</span>
                                                </div>
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">繳費年期</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{item.YPeriod} 年期</span>
                                                </div>
                                            </li>
                                            <li className="row px-2">
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">商品名稱</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{item.Pro_Name}</span>
                                                </div>
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">保額/單位</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{toCurrency(Math.round(item.Benefits))} {item.Unit}</span>
                                                </div>
                                            </li>
                                            <li className="row px-2">
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">保費/單位</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{toCurrency(Math.round(item.FYP))} 元</span>
                                                </div>
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">被保人姓名</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{item.IName}</span>
                                                </div>
                                            </li>
                                            <li className="row px-2">
                                                <div className="col-md-6 d-flex border-bottom py-3">
                                                    <span className="d-block query-title">與要保人關係</span>
                                                    <span className="d-block text-dark-blue fw-bolder">{item.Relationship}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    )
                                }
                            } else {
                                return (
                                    <Accordion className="my-2">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <span className="fw-bolder text-dark-blue">{item.Pro_Name}</span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="list-unstyles p-0">
                                                    <li className="row px-2">
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">生效日</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{dateChange(item.Effe_Date)}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">繳費年期</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{item.YPeriod} 年期</span>
                                                        </div>
                                                    </li>
                                                    <li className="row px-2">
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">保額/單位</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{toCurrency(Math.round(item.Benefits))} {item.Unit}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">保費/單位</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{toCurrency(Math.round(item.FYP))} 元</span>
                                                        </div>
                                                    </li>
                                                    <li className="row px-2">
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">被保人姓名</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{item.IName}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex border-bottom py-3">
                                                            <span className="d-block query-title">與要保人關係</span>
                                                            <span className="d-block text-dark-blue fw-bolder">{item.Relationship}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            }
                        })
                    }
                </div>
                <div className="d-table" style={{ height: '60px' }}></div>
                <button type="button" className={`${searchInput == null ? 'w-100' : 'w-50'} btn btn-golden-brown position-fixed py-3 fw-bolder`} style={SearchReset} onClick={e => handleSearchReset(e, 'reset')}>重新查詢</button>
                {
                    searchInput !== null &&
                    <button type="button" className="w-50 btn btn-golden-brown position-fixed py-3 fw-bolder r-0" style={SearchReset} onClick={e => handleSearchReset(e)}>接續查詢</button>
                }
            </BackgroundWrapper>
        </>
    )
}

export default InsurancePolicyRead