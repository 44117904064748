import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
//components
import BackgroundWrapper from "../../components/BackgroundWrapper";
import Loading from "../../components/Loading";
import { dateChange } from '../../assets/javascript/calTool'
import InsideNav from '../../components/InsideNav';

import LineLogin from '../../assets/javascript/lineLogin'
import Countfunc from '../../assets/javascript/countfunc'

//icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Decrypt, Encrypt } from "../../assets/javascript/AESTool";

const InsurancPolicy = () => {
    const insideNavItem = [{ text: '壽險', }, { text: '產險', }] //inside 切換選單
    let [isLoading, setIsLoading] = useState(true)
    let [searchInput, setSearchInput] = useState('');
    let [getInsData, setGetInsData] = useState([]);
    let [data, setData] = useState([]);
    let [filterData, setFilterData] = useState([]);
    let [nodataText, setNodataText] = useState('');
    let [option, setOption] = useState(null); //預設為:壽險

    useEffect(() => {
        Countfunc('保單查詢', 19)
    }, [])

    useEffect(() => {
        LineLogin('/insurance_policy'); //LINE 登入狀態驗證
        if (option !== null) {
            let API = `${process.env.REACT_APP_GOLDEN_API5000}CusList`; //壽險"1" 產險"100"
            if (option == '壽險') {
                API = `${API}/Type=1`
            } else if (option == '產險') {
                API = `${API}/InsType`
            }
            axios.get(API, {
                withCredentials: true,
                headers: { 'Access-Control-Allow-Credentials': 'true' }
            })
                .then((res) => {
                    setTimeout(() => {
                        if (res.data.ResponseCode === '-1') {
                            localStorage.setItem('page', '/insurance_policy');
                            window.location.href = '/login'
                            return
                        } else if (res.data.ResponseCode === '0') {
                            setIsLoading(false)
                            setGetInsData(res.data.Data)
                        } else if (res.data.ResponseCode === '-20') {
                            setNodataText('無資料')
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            alert('查詢失敗!')
                            return
                        }
                    }, Number(process.env.REACT_APP_TIMEOUT))
                })
                .catch((err) => {
                    alert('請重新整理')
                    console.log('重新整理')
                })
        }
    }, [option]);

    const getMainInsData = () => {
        if (getInsData.length !== 0) {
            getInsData.forEach((kidItem, kidIndex) => {
                kidItem.InsData.forEach((aItem, aIndex, aArray) => {
                    aItem.forEach((item, index) => {
                        if (item.Main == '1' || item.Main == null) {
                            setData(oldArray => [...oldArray, item])
                            setIsLoading(false)
                        }
                    })
                })
            })
        }
    }
    useEffect(() => {
        getMainInsData();
    }, [getInsData])

    let showInsData = [];
    const showInsDataFun = () => {
        const set = new Set()
        showInsData = data.filter(item => !set.has(item.Ins_No) ? set.add(item.Ins_No) : false);
    }
    showInsDataFun()

    //--- 保險類型切換
    const handleTypeClick = (e) => {
        e.preventDefault();
        let { nodeName, innerText } = e.target
        if (nodeName !== 'A') {
            return
        }
        if (option === innerText) {
            return
        }
        setOption(innerText)
        setData([])
        setFilterData([])
        setSearchInput('')
        setIsLoading(true)
        setDropDown(false)
        setCurrentPage(1)
    }

    let [dropDown, setDropDown] = useState(false)
    const handleDropDown = () => {
        if (dropDown) {
            setDropDown(false)
        } else {
            setDropDown(true)
        }
    }

    //搜尋
    const searchDoneData = () => {
        showInsData.forEach((val, index) => {
            if (searchInput.replace(/\s+/g, "") === '') {
                setFilterData(old => [...old, val])
                sessionStorage.removeItem('searchInput')
            } else if (val?.Supplier_Name?.includes(searchInput.replace(/\s+/g, "")) || val?.Ins_No?.includes(searchInput.replace(/\s+/g, "")) || val?.name?.includes(searchInput.replace(/\s+/g, "")) || val?.Name?.includes(searchInput.replace(/\s+/g, ""))) {
                sessionStorage.removeItem('searchInput')
                let key = process.env.REACT_APP_KEY;
                let iv = process.env.REACT_APP_IV;
                sessionStorage.setItem('searchInput', Encrypt(JSON.stringify({ option: option, searchInput: searchInput.replace(/\s+/g, "") }), key, iv))
                setFilterData(old => [...old, val])
            }
        })
    }
    useEffect(() => {
        if (sessionStorage.getItem('searchInput') !== null) {
            setSearchInput(JSON.parse(Decrypt(sessionStorage.getItem('searchInput'), process.env.REACT_APP_KEY, process.env.REACT_APP_IV))?.searchInput)
            setOption(JSON.parse(Decrypt(sessionStorage.getItem('searchInput'), process.env.REACT_APP_KEY, process.env.REACT_APP_IV))?.option)
            setDropDown(true)
        } else {
            setOption('壽險')
        }
    }, [])
    useEffect(() => {
        searchDoneData()
    }, [searchInput, data]);
    //分頁邏輯 start
    //分頁初始資料
    let [currentPage, setCurrentPage] = useState(1); //當前頁數
    let [itemsPerPage] = useState(5); //預設一頁顯示筆數
    let [pageNumberLimit] = useState(5); //每次顯示頁數限制
    let [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5) //最大頁數限制
    let [minPageNumberLimit, setMinPageNumberLimit] = useState(0) //最小頁數限制

    const handleCurrentPage = (e) => {
        // e.preventDefault();
        let { id } = e.target;
        setCurrentPage(Number(id))
    }

    let pages = []; //總共分成幾頁
    for (let i = 1; i <= Math.ceil(filterData.length / itemsPerPage); i++) {
        pages.push(i);
    }

    //預設 currentPage 為 1; itemsPerPage 為 5筆
    const indexOfLastItem = currentPage * itemsPerPage; //5
    const indexOfFirstItem = indexOfLastItem - itemsPerPage; //0
    const currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem); // MAP 的資料
    const renderPageNumbers = pages.map((item) => {
        if (item < maxPageNumberLimit + 1 && item > minPageNumberLimit) {
            return (
                <li className={`page-item`} key={item} id={item} onClick={handleCurrentPage}>
                    <a href="#" className={`page-link ${currentPage === item ? "page-active" : ""}`} id={item}>{item}</a>
                </li>
            )
        } else {
            return null
        }
    })

    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }
    const handlePretBtn = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    let pageAddBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageAddBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handleNextBtn}>&hellip;</a>
        </li>
    }
    let pageReduceBtn = null;
    if (minPageNumberLimit >= 1) {
        pageReduceBtn = <li className={`page-item`} >
            <a href="#" className={`page-link px-2`} onClick={handlePretBtn}>&hellip;</a>
        </li>
    }
    //分頁邏輯 end
    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        setFilterData([])
        setCurrentPage(1)
    }

    useEffect(() => {
    }, [currentPage]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <BackgroundWrapper>
                <div className="position-fixed w-100 bg-light" style={{ zIndex: '30' }}>
                    <div className={`top-nav w-100 py-3`}>
                        <button className="btn w-100 p-0" onClick={handleDropDown}>
                            <p className="text-light text-center fw-bolder">保單查詢</p>
                            <span className={` position-absolute ${dropDown ? 'times-icon' : 'search-icon'}`} style={{ right: '23px', top: '16px' }}></span>
                        </button>
                        <div className={`container pt-3 ${dropDown ? 'd-block' : 'd-none'}`}>
                            <div className="search-box">
                                <p className="text-light">＊提供保單號碼、保險公司、要被保人查詢</p>
                                <input type="text" className="form-control my-3" value={searchInput} onChange={handleSearchInput} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-table" style={dropDown ? { height: '169px' } : { height: '60px' }}></div>
                <InsideNav option={option} insideNavItem={insideNavItem} handleClick={handleTypeClick}></InsideNav>
                <div className="container">
                    <div className="row mt-3">
                        <p className={`${nodataText === '無資料' ? 'py-4' : ''}`}>{nodataText}</p>
                        {
                            currentItems.map((item, index) => {
                                return (
                                    <Link key={index} className="col-md-6 col-lg-4 my-2 cursor-pointer" to={`/insurance_policy_read/${item.PayerCode}/${item.Ins_No}`}>
                                        <div className="card bg-light card-shadow">
                                            <h6 className="bg-dark-blue px-3 py-2 d-flex flex-wrap justify-content-between align-items-center text-light text-center fw-bolder rounded-top">
                                                <span className="d-block">{item.Supplier_Name}</span>
                                                <span className="d-block">{item.Ins_No}</span>
                                            </h6>
                                            <div className="card-body py-1">
                                                <ul className="list-unstyled">
                                                    {
                                                        item.Main == 1 && item.MainVoid &&
                                                        <li>
                                                            <span className="badge bg-secondary">已註銷</span>
                                                        </li>
                                                    }
                                                    <li className="row py-2">
                                                        <span className="d-block text-golden-brown fw-bolder" style={{ fontSize: '18px' }}>{item.Pro_Name}</span>
                                                    </li>
                                                    <li className="row py-1">
                                                        <span className="d-block col-4 text-dark">要/被保人</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">{item.name}/{item.IName}</span>
                                                    </li>
                                                    <li className="row py-1">
                                                        <span className="d-block col-4 text-dark">生效日</span>
                                                        <span className="d-block col-8 text-golden-brown fw-bolder">{dateChange(item.Effe_Date)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="py-3">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[0] ? "page-disabled d-none" : ""}`} onClick={handlePretBtn}><FontAwesomeIcon icon={faChevronLeft} /></a>
                            </li>
                            {pageReduceBtn}
                            {renderPageNumbers}
                            {pageAddBtn}
                            <li className={`page-item`} >
                                <a href="#" className={`page-link ${currentPage === pages[pages.length - 1] ? "page-disabled d-none" : ""}`} onClick={handleNextBtn}><FontAwesomeIcon icon={faChevronRight} /></a>
                            </li>
                        </ul>
                        {/* <div className="d-flex justify-content-center my-3">
                            <button type="button" className={`btn btn-outline-dark-blue text-center ${currentItems.length < 10 || currentItems.length == resultData.length ? 'd-none' : 'd-block'}`} onClick={handleLoadMore}>一次瀏覽更多</button>
                        </div> */}
                    </nav>
                </div>
            </BackgroundWrapper>
        </>
    )
}

export default InsurancPolicy